
class Config {
  static apiBaseURL = "http://api.harshpatel.in/api/";
  static apiTokenURL = "http://api.harshpatel.in/token";
  static BaseURL = "http://api.harshpatel.in/";

  // static apiTokenURL = "http://localhost:21551/token";
  // static apiBaseURL = "http://localhost:21551/api/";
  // static BaseURL = "http://localhost:21551/";

}

export default Config;
