import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer-top section-padding">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 col-sm-4 col-6 mb-sm-20">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-credit-card-1"></i></span>
                                    <span className="text-light-black">100% Payment<br />Secured</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6 mb-sm-20">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-wallet-1"></i></span>
                                    <span className="text-light-black">Support lots<br /> of Payments</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6 mb-sm-20">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-help"></i></span>
                                    <span className="text-light-black">24 hours / 7 days<br />Support</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-truck"></i></span>
                                    <span className="text-light-black">Free Delivery<br />with $50</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-guarantee"></i></span>
                                    <span className="text-light-black">Best Price<br />Guaranteed</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-app-file-symbol"></i></span>
                                    <span className="text-light-black">Mobile Apps<br />Ready</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="section-padding bg-light-theme pt-0 u-line">
                    <div className="u-line instagram-slider swiper-container">
                        <ul className="hm-list hm-instagram swiper-wrapper">
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                        </ul>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                                <div className="footer-contact">
                                    <h6 className="text-light-black">Need Help</h6>
                                    <ul>
                                        <li className="fw-600"><span className="text-light-white">Call Us</span> <a href="tel:"
                                            className="text-light-black">+(347) 123 456 789</a>
                                        </li>
                                        <li className="fw-600"><span className="text-light-white">Email Us</span> <a href="mailto:"
                                            className="text-light-black">demo@domain.com</a>
                                        </li>
                                        <li className="fw-600"><span className="text-light-white">Join our twitter</span> <a href="#"
                                            className="text-light-black">@quickmunch</a>
                                        </li>
                                        <li className="fw-600"><span className="text-light-white">Join our instagram</span> <a href="#"
                                            className="text-light-black">@quickmunch</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                                <div className="footer-links">
                                    <h6 className="text-light-black">Get to Know Us</h6>
                                    <ul>
                                        <li><a href="#" className="text-light-white fw-600">About Us</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Blog</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Socialize</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Munchbox</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Perks</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                                <div className="footer-links">
                                    <h6 className="text-light-black">Let Us Help You</h6>
                                    <ul>
                                        <li><a href="#" className="text-light-white fw-600">Account Details</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Order History</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Find restaurant</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Login</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Track order</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                                <div className="footer-links">
                                    <h6 className="text-light-black">Doing Business</h6>
                                    <ul>
                                        <li><a href="#" className="text-light-white fw-600">Suggest an Idea</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Be a Partner restaurant</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Create an Account</a>
                                        </li>
                                        <li><a href="#" className="text-light-white fw-600">Help</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                                <div className="footer-links">
                                    <h6 className="text-light-black">Download Apps</h6>
                                    <div className="appimg">
                                        <a href="#">
                                            <img src="https://via.placeholder.com/150x40" className="img-fluid" alt="app logo" />
                                        </a>
                                    </div>
                                    <div className="appimg">
                                        <a href="#">
                                            <img src="https://via.placeholder.com/150x40" className="img-fluid" alt="app logo" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                                <div className="footer-contact">
                                    <h6 className="text-light-black">Newsletter</h6>
                                    <form className="subscribe_form">
                                        <div className="input-group">
                                            <input type="text" className="form-control form-control-submit" name="email" placeholder="Enter your email" />
                                            <span className="input-group-btn">
                                                <button className="btn btn-second btn-submit" type="button"><i className="fas fa-paper-plane"></i></button>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="ft-social-media">
                                    <h6 className="text-center text-light-black">Follow us</h6>
                                    <ul>
                                        <li> <a href="#"><i className="fab fa-facebook-f"></i></a>
                                        </li>
                                        <li> <a href="#"><i className="fab fa-twitter"></i></a>
                                        </li>
                                        <li> <a href="#"><i className="fab fa-instagram"></i></a>
                                        </li>
                                        <li> <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                        </li>
                                        <li> <a href="#"><i className="fab fa-youtube"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="copyright">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="payment-logo mb-md-20"> <span className="text-light-white fs-14 mr-3">We are accept</span>
                                    <div className="payemt-icon">
                                        <img src="https://via.placeholder.com/60x30" alt="#" />
                                        <img src="https://via.placeholder.com/60x30" alt="#" />
                                        <img src="https://via.placeholder.com/60x30" alt="#" />
                                        <img src="https://via.placeholder.com/60x30" alt="#" />
                                        <img src="https://via.placeholder.com/60x30" alt="#" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center medewithlove align-self-center">
                                <a href="http://www.slidesigma.com" className="text-light-black">Made with Real <i className="fas fa-heart"></i>
            Slidesigma</a>
                            </div>
                            <div className="col-lg-4">
                                <div className="copyright-text"> <span className="text-light-black">© <a href="#"
                                    className="text-light-white">Slidesigma</a> - 2019 | All Right Reserved</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="address-box">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title fw-700">Change Address</h4>
                            </div>
                            <div className="modal-body">
                                <div className="location-picker">
                                    <input type="text" className="form-control" placeholder="Enter a new address" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="search-box">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <div className="search-box p-relative full-width">
                                    <input type="text" className="form-control" placeholder="Pizza, Burger, Chinese" />
                                </div>
                            </div>
                            <div className="modal-body"></div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
