import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import dashboard from './Modules/Default/index';
import Login from './Modules/Login/LoginContent'
import Registration from './Modules/Defaultregister/Defaultregister'
import Restaurent from './Modules/Restaurent/index';
import CustomerProfile from './Modules/Customer/CustomerProfile'
/* ========================  Project Link  ========================================*/


/* ========================  Project Link  End ========================================*/


function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={ dashboard } />
          <Route path="/Login" component={ Login }></Route>
          <Route path="/Registration" component={ Registration }></Route>
          <Route path="/Restaurent/:id" component={ Restaurent }></Route>
          <Route path="/CustomerProfile" component={ CustomerProfile }></Route>
        </Switch>
      </Router>
      {/* <LoadCommanJS></LoadCommanJS> */ }
    </>
  );
}

export default App;
