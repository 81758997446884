import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ApiInstance from '../../Utilities/axiosConfig';
import { renderIntoDocument } from 'react-dom/test-utils';
import Footer from '../Footer'
import Header from '../Header'
import Config from '../../Config';

export default class CustomerProfile extends Component {
    constructor () {
        super();

        this.state = {
            RegistrationData: {},
            HomeAddress: {},
            OfficeAddress: {},
            OtherAddress: {},
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false,
            TermsAndCondition: true,
            ActiveClassId: 1,
            ActiveClassPayment: 1,
            ActiveClassAddress: 1,
            file: ''
        }


        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        this._checkboxChangeHandler = this.checkboxChangeEvent.bind(this)


    }
    componentDidMount() {
        // //initialize datatable
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {


                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);
        }
        formValidation();
        this._handleSubmitEvent = this.handleSubmit.bind(this);

        this.GetCustomerProfile();
    }

    ItemChangeEvent = event => {

        const value = event.target.value;
        const key = event.target.name;

        if (key === 'FirstName' || key === 'LastName') {
            var regex = /^[a-zA-Z ]*$/;
            if (value.match(regex)) {

                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }
        }
        else {

            let RegistrationData = this.state.RegistrationData;
            RegistrationData[key] = value;

            this.setState({
                RegistrationData: RegistrationData
            });

        }
    }

    resetFormData = () => {

        const clonedState = this.state.RegistrationData;
        const keys = Object.keys(clonedState);
        keys.forEach(key => (delete clonedState[key]));

        this.setState({
            RegistrationData: {},
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false
        });

        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.classList.remove('was-validated');
        }, false);

    }

    checkboxChangeEvent(event, c) {

        const value = event.target.checked;
        const key = event.target.name;
        console.log(c)
        switch (c) {
            case 'h':
                {
                    let HomeAddress = this.state.HomeAddress;
                    let OfficeAddress = this.state.OfficeAddress;
                    let OtherAddress = this.state.OtherAddress;

                    HomeAddress.IsDefault = value;
                    if (value) {
                        OfficeAddress.IsDefault = false;
                        OtherAddress.IsDefault = false;
                    }
                    this.setState({
                        HomeAddress: HomeAddress, OfficeAddress: OfficeAddress, OtherAddress: OtherAddress
                    });
                    break;
                }
            case 'off':
                {
                    let HomeAddress = this.state.HomeAddress;
                    let OfficeAddress = this.state.OfficeAddress;
                    let OtherAddress = this.state.OtherAddress;

                    OfficeAddress.IsDefault = value;
                    if (value) {
                        HomeAddress.IsDefault = false;
                        OtherAddress.IsDefault = false;
                    }
                    this.setState({
                        HomeAddress: HomeAddress, OfficeAddress: OfficeAddress, OtherAddress: OtherAddress
                    });
                    break;
                }
            case 'o':
                {
                    let HomeAddress = this.state.HomeAddress;
                    let OfficeAddress = this.state.OfficeAddress;
                    let OtherAddress = this.state.OtherAddress;

                    OtherAddress.IsDefault = value;
                    if (value) {
                        OfficeAddress.IsDefault = false;
                        HomeAddress.IsDefault = false;
                    }
                    this.setState({
                        HomeAddress: HomeAddress, OfficeAddress: OfficeAddress, OtherAddress: OtherAddress
                    });
                    break;
                }
            default:
                {
                    let RegistrationData = this.state.RegistrationData;
                    RegistrationData[key] = value;
                    this.setState({
                        RegistrationData: RegistrationData
                    });
                }

        }



    }

    validatePassword() {

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,20})");
        var txtPassword = document.getElementById("Password");

        if (!strongRegex.test(txtPassword.value)) {
            document.getElementById("ValidatePassword").innerHTML = "Password must be contain min 6 and max 20 character with the combination of uppercase ,lower case, numeric and special char";
            return false;
        }
        else {
            document.getElementById("ValidatePassword").innerHTML = "";
            return true;
        }

    }

    setFile(e) {
        if (this.validate(e.target)) {
            this.setState({ file: e.target.files[0] });
            console.log(e.target.files[0]);
        }
    }
    validate(file) {

        var valid = true;
        var file_name = "";
        var file_type = "";
        var file_size = "";
        var error_msg = "";
        var valid_size = 5 * 1000 * 1000;
        var display_error = document.getElementById('file_error');

        if (file.files.length != 0) {
            file_name = file.files[0].name;
            file_size = file.files[0].size;
            file_type = file.files[0].type;

            if (file_type != "image/png" && file_type != "image/jpeg" && file_type != "image/pdf" && file_type != "image/bmp") {
                valid = false;
                error_msg = error_msg + "\n* Only 'PNG', 'JPG/JPEG','BMP' and 'PDF' file type supported.";
            }
            if (file_size > valid_size) {
                valid = false;
                error_msg = error_msg + "\n* Filesize should be upto 5MB.";
            }
        }
        else {
            valid = false;
            error_msg = error_msg + "\n* Please select any image file.";
        }
        if (valid == true) {

            /*Write ajax code here to send file to the server.*/
            return true;
        }
        else {
            display_error.innerHTML = error_msg;
            return false;
        }
    }


    GetCustomerProfile() {

        ApiInstance().get('CustomerMaster/SelectCustomerProfileAddressByCustomerId')
            .then(response => {
                var data = response;
                if (data) {
                    let RegistrationData = JSON.parse(data.data.data).Data[0]
                    let tblCustomerAddressMasters = RegistrationData.tblCustomerAddressMasters;


                    let HomeAddress = {};

                    if (tblCustomerAddressMasters.length > 0) {
                        HomeAddress = tblCustomerAddressMasters.find(element => element.AddressName.toLowerCase() == 'home')
                        this.setAddressObject('h', HomeAddress);
                    }

                    let OfficeAddress = {};
                    if (tblCustomerAddressMasters.length > 0) {
                        OfficeAddress = tblCustomerAddressMasters.find(element => element.AddressName.toLowerCase() == 'office')
                        this.setAddressObject('off', OfficeAddress);
                    }

                    let OtherAddress = {}
                    if (tblCustomerAddressMasters.length > 0) {
                        OtherAddress = tblCustomerAddressMasters.find(element => element.AddressName.toLowerCase() == 'other')
                        this.setAddressObject('o', OtherAddress);
                    }
                    this.setState({
                        RegistrationData: RegistrationData, HomeAddress: HomeAddress, OfficeAddress: OfficeAddress, OtherAddress: OtherAddress
                    });
                }
            }, error => {
                console.log(error);
            });
    }


    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false

        })

        if (event.target.checkValidity()) {

            let RegistrationData = this.state.RegistrationData
            let tblCustomerAddressMasters = [];

            let HomeAddress = this.getAddressObject('h', this.state.HomeAddress);
            let OfficeAddress = this.getAddressObject('off', this.state.OfficeAddress)
            let OtherAddress = this.getAddressObject('o', this.state.OtherAddress)

            tblCustomerAddressMasters.push(HomeAddress);
            tblCustomerAddressMasters.push(OfficeAddress);
            tblCustomerAddressMasters.push(OtherAddress);

            RegistrationData.tblCustomerAddressMasters = tblCustomerAddressMasters;

            console.log(JSON.stringify(this.state.RegistrationData));

            const formData = new FormData();
            formData.append("file", this.state.file);
            formData.append("data", JSON.stringify(RegistrationData));

            ApiInstance().post("CustomerMaster/InsertUpdatetblCustomerProfileAddressMaster", formData, { headers: { 'Content-Type': 'multipart/form-data', } })
                .then(response => {
                    let data = response.data;
                    console.log(data);
                    if (data.status) {
                        // this.resetFormData();
                    }
                    else {
                        // if (data.data.toLowerCase().includes("username")) {
                        //     this.setState({ IsUserNameExist: true }, () => { })
                        // }

                        // if (data.data.toLowerCase().includes("email")) {
                        //     this.setState({ IsEmailExist: true }, () => { })
                        // }

                        // if (data.data.toLowerCase().includes("mobileno")) {
                        //     this.setState({ IsMobileExist: true }, () => { })
                        // }
                    }

                }, error => {
                    //window.init_PNotify("Error", "error", "Record not updated!!");
                    console.log(error);
                });



        }
        else {
            console.log(event.target.checkValidity())
        }

    }

    setPanelVisibality(id) {
        this.setState({ ActiveClassId: id })
    }

    setPaymentVisibality(id) {
        this.setState({ ActiveClassPayment: id });
    }
    setAddressVisibality(id) {
        this.setState({ ActiveClassAddress: id });
    }

    setAddressObject(c, obj) {
        if (obj) {
            console.log(obj.State)
            document.getElementById(c + "Address1").value = (obj.Address1 !== undefined) ? obj.Address1 : '';
            document.getElementById(c + "LandMark").value = (obj.LandMark) ? obj.LandMark : '';
            document.getElementById(c + "State").value = (obj.State) ? obj.State : '';
            document.getElementById(c + "City").value = (obj.City) ? obj.City : '';
            document.getElementById(c + "Pincode").value = (obj.Pincode) ? obj.Pincode : '';
            document.getElementById(c + "Latitude").value = (obj.Latitude) ? obj.Latitude : '';
            document.getElementById(c + "Longitude").value = (obj.Longitude) ? obj.Longitude : '';
            document.getElementById(c + "IsDefault").checked = (obj.IsDefault != null) ? obj.IsDefault : false;
            document.getElementById(c + "Street").value = (obj.Street) ? obj.Street : '';
            document.getElementById(c + "Country").value = (obj.Country) ? obj.Country : '';
        }
    }
    getAddressObject(c, obj) {

        obj.Address1 = document.getElementById(c + "Address1").value
        obj.LandMark = document.getElementById(c + "LandMark").value;
        obj.State = document.getElementById(c + "State").value;
        obj.City = document.getElementById(c + "City").value;
        obj.Pincode = document.getElementById(c + "Pincode").value;
        obj.Latitude = document.getElementById(c + "Latitude").value;
        obj.Longitude = document.getElementById(c + "Longitude").value;
        obj.IsDefault = document.getElementById(c + "IsDefault").checked;
        obj.Street = document.getElementById(c + "Street").value;
        obj.Country = document.getElementById(c + "Country").value;

        switch (c) {
            case 'h':
                obj.AddressName = 'Home';
                break;
            case 'off':
                obj.AddressName = 'Office';
                break;
            case 'o':
                obj.AddressName = 'Other';
                break;

        }
        return obj;
    }

    render() {

        return (
            <>
                <Header></Header>
                <div className="main-sec"></div>
                <section className="register-restaurent-sec section-padding bg-light-theme">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="sidebar-tabs main-box padding-20 mb-md-40">
                                    <div id="add-restaurent-tab" className="step-app">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-5 mb-md-40">
                                                <ul className="step-steps steps-2">

                                                    <li className={ (this.state.ActiveClassId == 1) ? "add-res-tab active" : "add-res-tab" } id="stepbtn1"> <a href="#" className="add-res-tab" onClick={ () => { this.setPanelVisibality(1) } }>General Info</a>
                                                    </li>
                                                    <li className={ (this.state.ActiveClassId == 2) ? "add-res-tab active" : "add-res-tab" } id="stepbtn2"> <a href="#" className="add-res-tab" onClick={ () => { this.setPanelVisibality(2) } }>Address</a>
                                                    </li>
                                                    <li className={ (this.state.ActiveClassId == 3) ? "add-res-tab active" : "add-res-tab" } style={ { display: "none" } } id="stepbtn3"> <a href="#" className="add-res-tab" onClick={ () => { this.setPanelVisibality(3) } }>Profile Pic.</a>
                                                    </li>
                                                    <li className={ (this.state.ActiveClassId == 4) ? "add-res-tab active" : "add-res-tab" } id="stepbtn4"> <a href="#" className="add-res-tab" onClick={ () => { this.setPanelVisibality(4) } }>Payment</a>
                                                    </li>
                                                    {/* <li className="add-res-tab" id="stepbtn4"> <a href="#" className="add-res-tab">Save &amp; Preview</a>
                                                    </li> */}

                                                </ul>
                                                {/* <div className="step-footer">
                                                    <button className="btn-first white-btn none" id="prev-1">Previous</button>
                                                    <button className="btn-first white-btn none" id="prev-2">Previous</button>
                                                    <button className="btn-first white-btn none" id="prev-3">Previous</button>
                                                    <button className="btn-first white-btn" id="next-1">Next</button>
                                                    <button className="btn-first white-btn none" id="next-2">Next</button>
                                                    <button className="btn-first white-btn none" id="next-3">Next</button>
                                                    <button className="btn-first white-btn none" id="finish-1">Finish</button>
                                                </div> */}
                                            </div>
                                            <div className="col-xl-8 col-lg-7">
                                                <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent } id="registrationform">
                                                    <div className="step-content">
                                                        {/* Personal Info. */ }
                                                        <div className={ (this.state.ActiveClassId == 1) ? "step-tab-panel active" : "step-tab-panel" } id="steppanel1">
                                                            <div className="general-sec">
                                                                <div className="row">

                                                                    <div className="col-10">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <h5 className="text-light-black fw-700">General Information</h5>
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label className="text-light-black fw-700">First Name<sup className="fs-16">*</sup>
                                                                                    </label>
                                                                                    <input type="text" name="FirstName" id="FirstName" value={ this.state.RegistrationData.FirstName || '' } className="form-control form-control-submit" placeholder="" readOnly={ true } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label className="text-light-black fw-700">Last Name</label>
                                                                                    <input type="text" name="LastName" value={ this.state.RegistrationData.LastName || '' } className="form-control form-control-submit" placeholder="" readOnly={ true } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label className="text-light-black fw-700">UserName</label>
                                                                                    <input type="text" name="UserName" value={ this.state.RegistrationData.UserName || '' } className="form-control form-control-submit" placeholder="" readOnly={ true } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label className="text-light-black fw-700">Manager Contact phone</label>
                                                                                    <input type="text" name="MobileNo" value={ this.state.RegistrationData.MobileNo || '' } className="form-control form-control-submit" placeholder="i.e +1 321 828 6662" readOnly={ true } onChange={ this.ItemChangeEvent } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="form-group">
                                                                                    <label className="text-light-black fw-700">Contact Email</label>
                                                                                    <input type="email" name="EmailId" value={ this.state.RegistrationData.EmailId || '' } className="form-control form-control-submit" placeholder="i.e alard@example.com " readOnly={ true } onChange={ this.ItemChangeEvent } />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className="col-2">
                                                                        <div className="col-md-12">
                                                                            <h5 className="text-light-black fw-700">Profile Pic</h5>
                                                                        </div>

                                                                        <div className="col-md-12">
                                                                            &nbsp;
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <img src={ Config.BaseURL + this.state.RegistrationData.Photo } alt="footer-img" onChange={ e => this.setFile(e) } />
                                                                            <br></br><br></br>
                                                                        </div>

                                                                        <div className="col-md-12">
                                                                            &nbsp;
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <input type="file" name="File" className="text-light-black fw-700" />
                                                                            <div className="invalid-feedback">
                                                                                Please select file.
                                                                                <div id="file_error" className="invalid-feedback d-block"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {/* Address */ }
                                                        <div className={ (this.state.ActiveClassId == 2) ? "step-tab-panel active" : "step-tab-panel" } id="steppanel2">
                                                            <div className="payment-sec">
                                                                <div className="section-header-left">
                                                                    <h3 className="text-light-black header-title">Address</h3>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div id="accordion">
                                                                            <div className="payment-option-tab">
                                                                                <ul className="nav nav-tabs">

                                                                                    <li className="nav-item"> <a className={ (this.state.ActiveClassAddress == 1) ? "nav-link fw-600 active" : "nav-link fw-600" } data-toggle="tab" href="#" onClick={ () => this.setAddressVisibality(1) }>Home</a>
                                                                                    </li>
                                                                                    <li className="nav-item"> <a className={ (this.state.ActiveClassAddress == 2) ? "nav-link fw-600 active" : "nav-link fw-600" } data-toggle="tab" href="#" onClick={ () => this.setAddressVisibality(2) }>Office</a>
                                                                                    </li>
                                                                                    <li className="nav-item"> <a className={ (this.state.ActiveClassAddress == 3) ? "nav-link fw-600 active" : "nav-link fw-600" } data-toggle="tab" href="#" onClick={ () => this.setAddressVisibality(3) }>Other</a>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className="tab-content">
                                                                                    {/* Home Address */ }
                                                                                    <div className={ (this.state.ActiveClassAddress == 1) ? "tab-pane active" : "tab-pane" } id="savecreditcard">
                                                                                        <div className="general-sec">
                                                                                            <div className="row">
                                                                                                <div className="col-md-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Find Location</label>
                                                                                                        <input type="text" name="HoneAddress" id="HoneAddress" className="form-control form-control-submit" placeholder="Type Your Address" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Full Address</label>
                                                                                                        <input type="text" name="hAddress1" id="hAddress1" className="form-control form-control-submit" placeholder="Type Your Address" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Street</label>
                                                                                                        <input type="text" name="hStreet" id="hStreet" className="form-control form-control-submit" placeholder="Street" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Landmark</label>
                                                                                                        <input type="text" name="hLandMark" id="hLandMark" className="form-control form-control-submit" placeholder="Landmark" />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">City</label>
                                                                                                        <input type="text" name="hCity" id="hCity" className="form-control form-control-submit" placeholder="City" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">State</label>
                                                                                                        <input type="text" name="hState" id="hState" className="form-control form-control-submit" placeholder="State" />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Country <sup className="fs-16">*</sup>
                                                                                                        </label>
                                                                                                        <input type="text" name="hCountry" id="hCountry" className="form-control form-control-submit" placeholder="Country" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Pincode <sup className="fs-16">*</sup>
                                                                                                        </label>
                                                                                                        <input type="text" name="hPincode" id="hPincode" className="form-control form-control-submit" placeholder="Pincode" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Latitude</label>
                                                                                                        <input type="hidden" name="hLatitude" id="hLatitude" className="form-control form-control-submit" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Longitude</label>
                                                                                                        <input type="hidden" name="hLongitude" id="hLongitude" className="form-control form-control-submit" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <input type="checkbox" name="hIsDefault" id="hIsDefault" checked={ this.state.HomeAddress.IsDefault || false } onChange={ (e) => { this._checkboxChangeHandler(e, 'h') } } />
                                                                                                        <label className="text-light-black fw-700">&nbsp;&nbsp;Is Default Address</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <p className="text-center text-light-black no-margin">By placing your order, you agree to Munchbox's <a href="#">terms of use</a> and <a href="#">privacy agreement</a>
                                                                                    </p> */}
                                                                                    </div>
                                                                                    {/* Office Address */ }
                                                                                    <div className={ (this.state.ActiveClassAddress == 2) ? "tab-pane active" : "tab-pane" } id="newcreditcard">
                                                                                        <div className="general-sec">

                                                                                            <div className="row">
                                                                                                <div className="col-md-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Find Location</label>
                                                                                                        <input type="text" name="OfficeAddress" id="OfficeAddress" className="form-control form-control-submit" placeholder="Type Your Address" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Full Address</label>
                                                                                                        <input type="text" name="offAddress1" id="offAddress1" className="form-control form-control-submit" placeholder="Type Your Address" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Street</label>
                                                                                                        <input type="text" name="offStreet" id="offStreet" className="form-control form-control-submit" placeholder="Street" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Landmark</label>
                                                                                                        <input type="text" name="offLandMark" id="offLandMark" className="form-control form-control-submit" placeholder="Landmark" />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">City</label>
                                                                                                        <input type="text" name="offCity" id="offCity" className="form-control form-control-submit" placeholder="City" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">State</label>
                                                                                                        <input type="text" name="offState" id="offState" className="form-control form-control-submit" placeholder="State" />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Country <sup className="fs-16">*</sup>
                                                                                                        </label>
                                                                                                        <input type="text" name="offCountry" id="offCountry" className="form-control form-control-submit" placeholder="Country" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Pincode <sup className="fs-16">*</sup>
                                                                                                        </label>
                                                                                                        <input type="text" name="offPincode" id="offPincode" className="form-control form-control-submit" placeholder="Pincode" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Latitude</label>
                                                                                                        <input type="hidden" name="offLatitude" id="offLatitude" className="form-control form-control-submit" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Longitude</label>
                                                                                                        <input type="hidden" name="offLongitude" id="offLongitude" className="form-control form-control-submit" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <input type="checkbox" name="offIsDefault" id="offIsDefault" checked={ this.state.OfficeAddress.IsDefault || false } onChange={ (e) => { this._checkboxChangeHandler(e, 'off') } } />
                                                                                                        <label className="text-light-black fw-700">&nbsp;&nbsp;Is Default Address</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>

                                                                                        {/* <p className="text-center text-light-black no-margin">By placing your order, you agree to Munchbox's <a href="#">terms of use</a> and <a href="#">privacy agreement</a>
                                                                                    </p> */}
                                                                                    </div>

                                                                                    {/* Other Address */ }
                                                                                    <div className={ (this.state.ActiveClassAddress == 3) ? "tab-pane active" : "tab-pane" } id="savecreditcard">
                                                                                        <div className="general-sec">

                                                                                            <div className="row">
                                                                                                <div className="col-md-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Find Location</label>
                                                                                                        <input type="text" name="OtherAddress" id="OtherAddress" className="form-control form-control-submit" placeholder="Type Your Address" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Full Address</label>
                                                                                                        <input type="text" name="oAddress1" id="oAddress1" className="form-control form-control-submit" placeholder="Type Your Address" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Street</label>
                                                                                                        <input type="text" name="oStreet" id="oStreet" className="form-control form-control-submit" placeholder="Street" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Landmark</label>
                                                                                                        <input type="text" name="oLandMark" id="oLandMark" className="form-control form-control-submit" placeholder="Landmark" />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">City</label>
                                                                                                        <input type="text" name="oCity" id="oCity" className="form-control form-control-submit" placeholder="City" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">State</label>
                                                                                                        <input type="text" name="oState" id="oState" className="form-control form-control-submit" placeholder="State" />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Country <sup className="fs-16">*</sup>
                                                                                                        </label>
                                                                                                        <input type="text" name="oCountry" id="oCountry" className="form-control form-control-submit" placeholder="Country" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Pincode <sup className="fs-16">*</sup>
                                                                                                        </label>
                                                                                                        <input type="text" name="oPincode" id="oPincode" className="form-control form-control-submit" placeholder="Pincode" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Latitude</label>
                                                                                                        <input type="hidden" name="oLatitude" id="oLatitude" className="form-control form-control-submit" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label className="text-light-black fw-700">Longitude</label>
                                                                                                        <input type="hidden" name="oLongitude" id="oLongitude" className="form-control form-control-submit" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <input type="checkbox" id="oIsDefault" name="oIsDefault" checked={ this.state.OtherAddress.IsDefault || false } onChange={ (e) => { this._checkboxChangeHandler(e, 'o') } } />
                                                                                                        <label className="text-light-black fw-700">&nbsp;&nbsp;Is Default Address</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>

                                                                                        {/* <p className="text-center text-light-black no-margin">By placing your order, you agree to Munchbox's <a href="#">terms of use</a> and <a href="#">privacy agreement</a>
                                                                                    </p> */}
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={ (this.state.ActiveClassId == 3) ? "step-tab-panel active" : "step-tab-panel" } id="steppanel1">
                                                            <div className="general-sec">

                                                                <div className="row">


                                                                </div>





                                                            </div>
                                                        </div>
                                                        {/* Payment */ }
                                                        <div className={ (this.state.ActiveClassId == 4) ? "step-tab-panel active" : "step-tab-panel" } id="steppanel3">
                                                            <div className="payment-sec">
                                                                <div className="section-header-left">
                                                                    <h3 className="text-light-black header-title">Payment information</h3>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div id="accordion">
                                                                            <div className="payment-option-tab">
                                                                                <ul className="nav nav-tabs">

                                                                                    <li className="nav-item"> <a className={ (this.state.ActiveClassPayment == 1) ? "nav-link fw-600 active" : "nav-link fw-600" } data-toggle="tab" href="#" onClick={ () => this.setPaymentVisibality(1) }>Saved credit card</a>
                                                                                    </li>
                                                                                    <li className="nav-item"> <a className={ (this.state.ActiveClassPayment == 2) ? "nav-link fw-600 active" : "nav-link fw-600" } data-toggle="tab" href="#" onClick={ () => this.setPaymentVisibality(2) }>New credit card</a>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className="tab-content">
                                                                                    <div className={ (this.state.ActiveClassPayment == 1) ? "tab-pane active" : "tab-pane" } id="savecreditcard">
                                                                                        <div className="form-group">
                                                                                            <div className="credit-card p-relative">
                                                                                                <input type="text" name="#" className="form-control form-control-submit" defaultValue="VISA....1877,exp 12/21" readOnly={ true } />
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <p className="text-center text-light-black no-margin">By placing your order, you agree to Munchbox's <a href="#">terms of use</a> and <a href="#">privacy agreement</a>
                                                                                    </p> */}
                                                                                    </div>


                                                                                    <div className={ (this.state.ActiveClassPayment == 2) ? "tab-pane active" : "tab-pane" } id="newcreditcard">
                                                                                        <div className="row">
                                                                                            <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="text-light-white fw-700">Card Number</label>
                                                                                                    <div className="credit-card card-front p-relative">
                                                                                                        <input type="text" name="#" className="form-control form-control-submit" placeholder="1234 5678 9101 1234" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-2 col-lg-6 col-md-2 col-sm-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="text-light-white fw-700">Expires on</label>
                                                                                                    <input type="text" name="#" className="form-control form-control-submit" placeholder="12/21" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="text-light-white fw-700">Security Code</label>
                                                                                                    <div className="credit-card card-back p-relative">
                                                                                                        <input type="text" name="#" className="form-control form-control-submit" placeholder={ 123 } />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="text-light-white fw-700">ZIP Code</label>
                                                                                                    <input type="text" name="#" className="form-control form-control-submit" placeholder={ 123456 } />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <div className="form-group">
                                                                                                    <label className="custom-checkbox">
                                                                                                        <input type="checkbox" name="#" onChange={ this._checkboxChangeHandler } /> <span className="checkmark" />
                                                                                                    Save Credit card</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className="section-header-left">
                                                                                        <h3 className="text-light-black header-title">Donate the change</h3>
                                                                                    </div> */}

                                                                                        {/* <p className="text-center text-light-black no-margin">By placing your order, you agree to Munchbox's <a href="#">terms of use</a> and <a href="#">privacy agreement</a>
                                                                                    </p> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="step-content">
                                                        {/* Personal Info. */ }

                                                        <div className="general-sec">
                                                            <div className="row">
                                                                <div className="col-4">&nbsp;</div>
                                                                <div className="col-4">
                                                                    <div className="form-group">
                                                                        <button type="submit" className="btn-first green-btn text-custom-white fw-500 lg-btn">Save</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">&nbsp;</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer></Footer>

            </>
        );
    }
}

