import React from 'react'
import ApiInstance from './Utilities/axiosConfig';

export const SetSessionData = () => {

  ApiInstance().get('Login/GetSessionData')
    .then(response => {

      localStorage.setItem("SessionData", JSON.stringify(response.data));

    }, error => {
      console.log(error);
    });

};

export const GetSessionData = () => JSON.parse(localStorage.getItem("tokenData"));


export const GetLinkedData = async (LinkedTabel, LinkedKeyColumn, LinkedValueColumn, FilterExpression) => {

  let para = {
    "tablename": LinkedTabel,
    "key": LinkedKeyColumn,
    "value": LinkedValueColumn,
    "filterExpression": FilterExpression
  };
  let jsondata = [];
  await ApiInstance().post('DataSource/GetLinkedData', para, { headers: { 'Content-Type': 'application/json' } })
    .then(function (response) {
      if (response.data) {
        jsondata = JSON.parse(response.data);


      }

    }, error => {
      console.log(error);
    });

  return jsondata;
}

export const ChangeDateFormatToLocal = (datevalue) => {
  return (datevalue != undefined) ? datevalue.toString().substr(0, 10) : "";
}
