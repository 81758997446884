import React, { Component } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import { GetSessionData } from '../../GlobalFactory';
import ApiInstance from '../../Utilities/axiosConfig';
import Config from '../../Config';

export default class index extends Component {

    constructor (props) {
        super(props)
        this.id = this.props.match.params.id;
        this.state = {
            RestarentData: {},
            DishList: [],
            DishData: {},
            DishId: '0',
            DishOrder: {
                DishId: "",
                CookId: "",
                DishName: "",
                CuisineId: "",
                CuisineName: "",
                FoodTypeId: 0,
                FoodTypeName: "",
                FoodMealTypeId: 0,
                FoodMealTypeName: "",
                FoodCategoryTypeId: 0,
                FoodCategoryTypeName: "",
                AllergensId: 0,
                AllergensName: "",
                Photo: "",
                TotalCalories: 0,
                DishVariantId: 0,
                VariantName: "",
                AddOnsArray: [],
                AddOnsTotal: 0,
                Price: 0,
                Discount: 0,
                PackingCharges: 0,
                FinalPrice: 0,
                Quantity: 0,
                QuantityType: "",
                BuyQuantity: 0,
                FinalTotalPerQuantity: 0,
                FinalTotalWithAddOns: 0,
            },
            CartOrder: {
                CustomerId: 1,
                CookId: this.id,
                TotalQty: 0,
                TotalAmount: 0,
                PaymnetMode: "cash",
                PaymentStatus: "Success",
                TransactionNumber: "ABCD1234",
                OrderStatusId: 1,
                ExtraNotes: "no onion & garlic",
                tblOrderChilds:
                    [
                        // {
                        //     DishId: "95",
                        //     CookId: "70",
                        //     DishName: "South Inidian",
                        //     CuisineId: "114",
                        //     CuisineName: "Cuisine name",
                        //     FoodTypeId: 164,
                        //     FoodTypeName: "Food Type NAme",
                        //     FoodMealTypeId: 5,
                        //     FoodMealTypeName: "Food meal type name",
                        //     FoodCategoryTypeId: 21,
                        //     FoodCategoryTypeName: "food category id",
                        //     AllergensId: 11,
                        //     AllergensName: "allergence name",
                        //     Photo: "/Resources/CookDishPhotos/70_637406652205624664.jpg",
                        //     TotalCalories: 1500,
                        //     DishVariantId: 161,
                        //     VariantName: "Double",
                        //     AddOnsArray: "[]",
                        //     AddOnsTotal: 10,
                        //     Price: 13,
                        //     Discount: 1,
                        //     PackingCharges: 1,
                        //     FinalPrice: 13.87,
                        //     Quantity: "1",
                        //     QuantityType: "PKG",
                        //     BuyQuantity: 2,
                        //     FinalTotalPerQuantity: 27,
                        //     FinalTotalWithAddOns: 37,
                        // }
                    ]
            }
        }

    }

    componentDidMount() {
        this.GetRestaurentData();
    }

    GetRestaurentData() {

        ApiInstance().get('CustomerHome/SelectCookProfileAndDishListByCookId?CookId=' + this.id)
            .then(response => {
                var data = response.data.data;

                if (data) {
                    let objdata = JSON.parse(data).Data
                    let DishList = JSON.parse(objdata[0].DishList)
                    this.setState({
                        RestarentData: objdata[0], DishList: DishList
                    });
                }
            }, error => {
                console.log(error);
            });


    }

    LoadDishiesList() {
        return (
            <>
                { this.state.DishList.map((item, index) =>
                    <div className="col-lg-12" key={ index }>
                        <div className="restaurent-product-list">
                            <div className="restaurent-product-detail">
                                <div className="restaurent-product-left">
                                    <div className="restaurent-product-title-box">
                                        <div className="restaurent-product-box">
                                            <div className="restaurent-product-title">
                                                <h6 className="mb-2" data-toggle="modal" data-target="#restaurent-popup"><a href="#" className="text-light-black fw-600" onClick={ () => { this.getDish(item.DishId) } }>{ item.DishName }</a></h6>
                                                <p className="text-light-white">{ item.FoodTypeName }</p>
                                                <p className="text-light-white">{ (item.TotalCalories) ? (item.TotalCalories + " Cal.") : "" }</p>
                                            </div>
                                            {/* <div className="restaurent-product-label"> <span className="rectangle-tag bg-gradient-red text-custom-white">Label</span>
                                                <span className="rectangle-tag bg-dark text-custom-white">Combo</span>
                                            </div> */}
                                        </div>
                                        <div className="restaurent-product-rating">
                                            <div className="ratings"> <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star-half-alt" /></span>
                                            </div>
                                            <div className="rating-text">
                                                {/* <p className="text-light-white fs-12 title">3845 ratings</p> */ }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="restaurent-product-caption-box"> <span className="text-light-white">{ item.Description }</span>
                                    </div>
                                    <div className="restaurent-tags-price">
                                        <div className="restaurent-tags">
                                            {/* <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/004-leaf.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/006-chili.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/005-chef.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/008-protein.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/009-lemon.svg" alt="tag" />
                                            </span> */}
                                        </div>
                                        <span className="circle-tag">
                                            <img src="/assets/img/svg/010-heart.svg" alt="tag" />
                                        </span>
                                        <div className="restaurent-product-price">
                                            <h6 className="text-success fw-600 no-margin">${ item.FinalPrice } </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="restaurent-product-img">
                                    <img src={ (item.Photo.length > 3) ? (Config.BaseURL + item.Photo) : "https://via.placeholder.com/150x151" } className="img-fluid" alt="#" />
                                </div>
                            </div>
                        </div>
                    </div>

                ) }
            </>
        )
    }

    getDish(DishId) {
        ApiInstance().get('CustomerHome/SelectDishMasterWithAddOnsAndVarientsByDishId?DishId=' + DishId)
            .then(response => {
                var data = response.data.data;

                if (data) {
                    let objdata = JSON.parse(data).Data
                    console.log(objdata[0])
                    this.setState({
                        DishData: objdata[0]
                    });
                }
            }, error => {
                console.log(error);
            });
    }

    DishNutritionsTable(nutritionobj = '[]') {

        let nutritionobjArr = JSON.parse(nutritionobj)


        return (
            <p className="text-light-black no-margin">
                Nutritions:
                {
                    nutritionobjArr.map((element, index) => <><br />{ ' ' + element.NutritionName + ' - ' + element.InitialValue + ' ' + element.Measurement }</>)
                }
            </p>
        )
    }

    AddonClickEvent(e, obj) {

        let DishOrder = this.state.DishOrder;
        let ifexist = DishOrder.AddOnsArray.find(ele => ele.DishAddOnsId == obj.DishAddOnsId)

        if (ifexist) {
            let tempaddonarr = DishOrder.AddOnsArray.filter(ele => ele.DishAddOnsId != obj.DishAddOnsId)
            DishOrder.AddOnsArray = tempaddonarr;
            document.getElementById(e.target.id).textContent = "+";
        }
        else {
            DishOrder.AddOnsArray.push(obj)
            document.getElementById(e.target.id).textContent = "-";
        }
        DishOrder.AddOnsTotal = DishOrder.Quantity * obj.AddOnsCharges;

        console.log(DishOrder)
        DishOrder.FinalPrice = DishOrder.FinalPrice + DishOrder.AddOnsTotal;
        this.setState({ DishOrder: DishOrder })
        //console.log(this.state.DishOrder)

    }

    AddOnList(addOnList = '[]') {
        addOnList = JSON.parse(addOnList)

        return (<>
            {  addOnList.map((element, index) =>
                <button className="add-pro" key={ index } id={ 'addonId' + index } onClick={ (event) => { this.AddonClickEvent(event, element) } } > { element.AddOnsName + '(' + element.FoodTypeName + ')' }
                    <span>+${ element.AddOnsCharges }</span><span className="close" id={ 'AddonSign' + index }>+</span>
                </button>
            )
            }
        </>
        )
    }

    CalculateQuantity(event, c) {

        // DishOrder: {
        //     TotalAmount: 0,
        //         AddOnsArray: [],
        //             OrderedDishList: [],

        //     }

        let qty = parseInt(document.getElementById('txtQtyR').value);

        if (c === '+') {
            qty = qty + 1
        }

        if (c === '-') {
            if ((qty - 1) < 0) {
                qty = 0;
            }
            else {
                qty = qty - 1
            }
        }
        document.getElementById('txtQtyR').value = qty;

        this.GenerateJson();
    }


    GenerateJson() {
        let variantid = document.getElementById("drpDisVariant").value;
        let qty = document.getElementById('txtQtyR').value

        let DishOrder = this.state.DishOrder;
        let DishData = this.state.DishData;

        DishOrder.DishId = DishData.DishId;
        DishOrder.CookId = this.id;
        DishOrder.DishName = DishData.DishName;
        DishOrder.CuisineId = DishData.CuisineId;
        DishOrder.CuisineName = DishData.CuisineName;
        DishOrder.FoodTypeId = DishData.FoodTypeId;
        DishOrder.FoodTypeName = DishData.FoodTypeName;
        DishOrder.FoodMealTypeId = DishData.FoodMealTypeId;
        DishOrder.FoodMealTypeName = DishData.FoodMealTypeName;
        DishOrder.FoodCategoryTypeId = DishData.FoodCategoryTypeId;
        DishOrder.FoodCategoryTypeName = DishData.FoodCategoryTypeName;
        DishOrder.AllergensId = DishData.AllergensId;
        DishOrder.AllergensName = DishData.AllergensName;
        DishOrder.Photo = DishData.Photo;
        DishOrder.TotalCalories = DishData.TotalCalories;

        DishOrder.DishVariantId = variantid;


        // DishOrder.AddOnsArray = [];
        //DishOrder.AddOnsTotal = 0;



        DishOrder.Quantity = DishData.Quantity;
        DishOrder.BuyQuantity = qty;


        if (variantid == 0) {
            DishOrder.QuantityType = (DishData.QuantityType) ? DishData.QuantityType : '';
            DishOrder.Price = (DishData.Price) ? DishData.Price : 0;
            DishOrder.VariantName = (DishData.VariantName) ? DishData.VariantName : '';
            DishOrder.Discount = (DishData.Discount) ? DishData.Discount : 0;
            DishOrder.PackingCharges = (DishData.PackingCharges) ? DishData.PackingCharges : 0;
        }
        else {
            if (this.state.DishData.tblDishVariants) {
                let tblDishvarient = JSON.parse(this.state.DishData.tblDishVariants)
                let varientobj = tblDishvarient.find(e => e.DishVariantId == variantid);
                console.log(tblDishvarient)
                console.log(varientobj)
                console.log(variantid)
                DishOrder.QuantityType = (varientobj.QuantityType) ? varientobj.QuantityType : ''
                DishOrder.Price = parseInt(varientobj.Price);
                DishOrder.VariantName = varientobj.VariantName;
                DishOrder.Discount = parseInt((varientobj.Discount) ? varientobj.Discount : 0);
                DishOrder.PackingCharges = parseInt((varientobj.PackingCharges) ? varientobj.PackingCharges : 0);
            }
        }

        DishOrder.FinalTotalPerQuantity = (DishOrder.BuyQuantity * DishOrder.Price);
        DishOrder.FinalTotalWithAddOns = (DishOrder.BuyQuantity * DishOrder.Price) + (DishOrder.AddOnsTotal);

        DishOrder.FinalPrice = (DishOrder.BuyQuantity * DishOrder.Price) + (DishOrder.AddOnsTotal) - ((DishOrder.Discount) ? DishOrder.Discount : 0) + ((DishOrder.PackingCharges) ? DishOrder.PackingCharges : 0);
        console.log(DishOrder)
        this.setState({ DishOrder: DishOrder })
    }

    QuantityList(dishVarient = '[]') {
        let dishVareintList = JSON.parse(dishVarient)

        return (
            <>
                <div className="product-quantity padding-10">
                    <span className="text-light-black fw-700 fs-16">
                        <select className="form-control" id="drpDisVariant">
                            <option value='0'>Regular  { '  $' + this.state.DishData.FinalPrice + '/' + this.state.DishData.QuantityType }</option>
                            { dishVareintList.map((item, index) =>
                                <option value={ item.DishVariantId }>{ item.VariantName + '   $' + item.Price + '/' + item.QuantityType }</option>
                            ) }
                        </select>
                    </span>
                    <div className="input-group quantity pull-right float-right">
                        <div className="input-group-append">
                            <button className="minus-btn" type="button" name="button" onClick={ (e) => this.CalculateQuantity(e, '-') }> <i className="fas fa-minus" />
                            </button>
                        </div>
                        <input type="text" className="text-center" id="txtQtyR" name="txtQtyR" defaultValue='0' min="0" />
                        <div className="input-group-prepend">
                            <button className="plus-btn" type="button" name="button" onClick={ (e) => this.CalculateQuantity(e, '+') }><i className="fas fa-plus" />
                            </button>
                        </div>

                    </div>
                </div>

            </>
        )

    }


    AddToCart() {
        let CartOrder = this.state.CartOrder;
        CartOrder.tblOrderChilds.push(this.state.DishOrder);

        let resetDishOrder = {
            DishId: "",
            CookId: "",
            DishName: "",
            CuisineId: "",
            CuisineName: "",
            FoodTypeId: 0,
            FoodTypeName: "",
            FoodMealTypeId: 0,
            FoodMealTypeName: "",
            FoodCategoryTypeId: 0,
            FoodCategoryTypeName: "",
            AllergensId: 0,
            AllergensName: "",
            Photo: "",
            TotalCalories: 0,
            DishVariantId: 0,
            VariantName: "",
            AddOnsArray: [],
            AddOnsTotal: 0,
            Price: 0,
            Discount: 0,
            PackingCharges: 0,
            FinalPrice: 0,
            Quantity: 0,
            QuantityType: "",
            BuyQuantity: 0,
            FinalTotalPerQuantity: 0,
            FinalTotalWithAddOns: 0,
        }

        this.setState({ CartOrder: CartOrder, DishOrder: resetDishOrder })
        document.getElementById("closemodel").click();
    }

    FinalCart() {

        console.log(this.state.CartOrder)
        let CartOrder = this.state.CartOrder;
        let tblOrderChilds = this.state.CartOrder.tblOrderChilds;
        tblOrderChilds.map((item, index) => {
            CartOrder.TotalAmount = CartOrder.TotalAmount + item.FinalTotalWithAddOns;
            CartOrder.TotalQty = CartOrder.TotalQty + item.BuyQuantity;
        })


        return (
            <div className="card-body no-padding" id="scrollstyle-4">
                { tblOrderChilds.map((item, index) =>
                    <div className="cat-product-box">
                        <div className="cat-product">
                            <div className="cat-name">
                                <a href="#">
                                    <p className="text-light-green fw-700"><span className="text-dark-white">{ index + 1 }</span> { item.DishName }</p> <span className="text-light-white fw-700"></span>
                                </a>
                            </div>
                            <div className="delete-btn">
                                <a href="#" className="text-dark-white"> <i className="far fa-trash-alt" />
                                </a>
                            </div>
                            <div className="price"> <a href="#" className="text-dark-white fw-500">
                                ${ item.FinalPrice }
                            </a>
                            </div>
                        </div>
                    </div>
                )
                }
                <div className="item-total">
                    <div className="total-price border-0"> <span className="text-dark-white fw-700">Items subtotal:</span>
                        <span className="text-dark-white fw-700">${ CartOrder.TotalAmount }</span>
                    </div>
                    <div className="empty-bag padding-15 fw-700"> <a href="#">Empty bag</a>
                    </div>
                </div>
            </div>
        )
    }
    ModelPopupItemList() {

        return (
            <>
                {/* product popup */ }
                <div className="modal fade restaurent-popup" id="restaurent-popup">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" id="closemodel" data-dismiss="modal">×</button>
                                <img src={ ((this.state.DishData.Photo) ? (this.state.DishData.Photo.length > 3) : false) ? (Config.BaseURL + this.state.DishData.Photo) : "https://via.placeholder.com/800x200" } className="img-fluid full-width" alt="#" />
                            </div>
                            <div className="modal-body">
                                <div className="name padding-10">
                                    <h3 className="text-light-black fw-700 mb-2">{ this.state.DishData.DishName }</h3>
                                    <h5 className="text-light-black fw-600 no-margin">$ { this.state.DishData.FinalPrice }</h5>
                                </div>
                                <div
                                    className="padding-10">
                                    <p className="text-light-black no-margin">{ 'Cuisine : ' + this.state.DishData.CuisineName }</p>
                                    <p className="text-light-black no-margin">{ 'Food Type :' + this.state.DishData.FoodTypeName }</p>
                                    <p className="text-light-black no-margin">{ this.state.DishData.Description }</p><br />
                                    { this.DishNutritionsTable(this.state.DishData.Nutritions) }


                                </div>
                                <div className="u-line">
                                    { this.QuantityList(this.state.DishData.tblDishVariants) }
                                </div>

                                {/* Price Varient product */ }

                                {/* Price Varient product End*/ }

                                {/* AddOns product */ }
                                <div className="additional-product">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="no-margin">
                                                <a className="card-link collapsed text-light-black fw-700" data-toggle="collapse" href="#additionalOne">
                                                    <span>Select AddOn
                                                    {/* <span className="text-light-white fw-500 fs-12 padding-tb-10">Select one (Required)</span> */ }
                                                    </span>
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="additionalOne" className="collapse">
                                            <div className="card-body padding-10">
                                                {/* <button className="add-pro">Small Premium Roast Coffee (0 Cal.) <span>+$0.59</span>
                                                    <span className="close">+</span>
                                                </button> */}

                                                { this.AddOnList(this.state.DishData.tblDishAddOns) }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* AddOns product End*/ }
                            </div>
                            <div className="modal-footer padding-10">
                                <button className="btn-second btn-submit" onClick={ () => { this.AddToCart() } }>Add Bag : ${ this.state.DishOrder.FinalPrice }</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Place all Scripts Here */ }
            </>
        )
    }

    render() {


        return (
            <>
                <Header></Header>
                {/* Restaurent body */ }
                <div>
                    {/* restaurent top */ }
                    <div className="page-banner p-relative smoothscroll" id="menu">
                        <img src={ ((this.state.RestarentData.ProfilePhoto) ? (this.state.RestarentData.ProfilePhoto.length > 3) : false) ? (Config.BaseURL + this.state.RestarentData.ProfilePhoto) : "https://via.placeholder.com/1920x270" } className="img-fluid full-width" alt="banner" />
                        <div className="overlay-2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="back-btn">
                                            <button type="button" className="text-light-green"> <i className="fas fa-chevron-left" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="tag-share"> <span className="text-light-green share-tag">
                                            <i className="fas fa-chevron-right" />
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* restaurent top */ }
                    {/* restaurent details */ }
                    <section className="restaurent-details  u-line">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="heading padding-tb-10">
                                        <h3 className="text-light-black title fw-700 no-margin">{ this.state.RestarentData.BussinessName }</h3>
                                        <p className="text-light-black sub-title no-margin">{ this.state.RestarentData.Address1 } <span><a href="checkout.html" className="text-success">Change locations</a></span>
                                        </p>
                                        <div className="head-rating">
                                            <div className="rating"> <span className="fs-16 text-yellow">
                                                <i className="fas fa-star" />
                                            </span>
                                                <span className="fs-16 text-yellow">
                                                    <i className="fas fa-star" />
                                                </span>
                                                <span className="fs-16 text-yellow">
                                                    <i className="fas fa-star" />
                                                </span>
                                                <span className="fs-16 text-yellow">
                                                    <i className="fas fa-star" />
                                                </span>
                                                <span className="fs-16 text-dark-white">
                                                    <i className="fas fa-star" />
                                                </span>
                                                <span className="text-light-black fs-12 rate-data">58 rating</span>
                                            </div>
                                            <div className="product-review">
                                                <div className="restaurent-details-mob">
                                                    <a href="#"> <span className="text-light-black"><i className="fas fa-info-circle" /></span>
                                                        <span className="text-dark-white">info</span>
                                                    </a>
                                                </div>
                                                <div className="restaurent-details-mob">
                                                    <a href="#"> <span className="text-light-black"><i className="fas fa-info-circle" /></span>
                                                        <span className="text-dark-white">info</span>
                                                    </a>
                                                </div>
                                                <div className="restaurent-details-mob">
                                                    <a href="#"> <span className="text-light-black"><i className="fas fa-info-circle" /></span>
                                                        <span className="text-dark-white">info</span>
                                                    </a>
                                                </div>
                                                <div className="restaurent-details-mob">
                                                    <a href="#"> <span className="text-light-black"><i className="fas fa-info-circle" /></span>
                                                        <span className="text-dark-white">info</span>
                                                    </a>
                                                </div>
                                                <h6 className="text-light-black no-margin">91<span className="fs-14">% Food was good</span></h6>
                                                <h6 className="text-light-black no-margin">91<span className="fs-14">% Food was good</span></h6>
                                                <h6 className="text-light-black no-margin">91<span className="fs-14">% Food was good</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="restaurent-logo">
                                        <img src="https://via.placeholder.com/80" className="img-fluid" alt="#" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* restaurent details */ }
                    {/* restaurent tab */ }
                    <div className="restaurent-tabs u-line">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="restaurent-menu scrollnav">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"> <a className="nav-link active text-light-white fw-700" data-toggle="pill" href="#menu">Menu</a>
                                            </li>
                                            <li className="nav-item"> <a className="nav-link text-light-white fw-700" data-toggle="pill" href="#about">About</a>
                                            </li>
                                            <li className="nav-item"> <a className="nav-link text-light-white fw-700" data-toggle="pill" href="#review">Reviews</a>
                                            </li>
                                            <li className="nav-item"> <a className="nav-link text-light-white fw-700" data-toggle="pill" href="#mapgallery">Map &amp; Gallery</a>
                                            </li>
                                        </ul>
                                        <div className="add-wishlist">
                                            <img src="/assets/img/svg/013-heart-1.svg" alt="tag" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* restaurent tab */ }
                    {/* restaurent address */ }
                    <div className="restaurent-address u-line">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="address-details">
                                        <div className="address">
                                            <div className="delivery-address"> <a href="order-details.html" className="text-light-black">Delivery, ASAP (45–55m)</a>
                                                <div className="delivery-type"> <span className="text-success fs-12 fw-500">No minimun</span><span className="text-light-white">, Free Delivery</span>
                                                </div>
                                            </div>
                                            <div className="change-address"> <a href="checkout.html" className="fw-500">Change</a>
                                            </div>
                                        </div>
                                        <p className="text-light-white no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* restaurent address */ }
                    {/* restaurent meals */ }
                    <section className="section-padding restaurent-meals bg-light-theme">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3">
                                    <div className="advertisement-slider swiper-container h-auto mb-xl-20 mb-md-40">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="testimonial-wrapper">
                                                    <div className="testimonial-box">
                                                        <div className="testimonial-img p-relative">
                                                            <a href="restaurant.html">
                                                                <img src="https://via.placeholder.com/438x180" className="img-fluid full-width" alt="testimonial-img" />
                                                            </a>
                                                            <div className="overlay">
                                                                <div className="brand-logo">
                                                                    <img src="https://via.placeholder.com/50" className="img-fluid" alt="logo" />
                                                                </div>
                                                                <div className="add-fav text-success">
                                                                    <img src="/assets/img/svg/013-heart-1.svg" alt="tag" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="testimonial-caption padding-15">
                                                            <p className="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                                            <h5 className="fw-600"><a href="restaurant.html" className="text-light-black">GSA King Tomato Farm</a></h5>
                                                            <div className="testimonial-user-box">
                                                                <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                                                <div className="testimonial-user-name">
                                                                    <p className="text-light-black fw-600">Sarra</p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                                                </div>
                                                            </div>
                                                            <div className="ratings"> <span className="text-yellow fs-16">
                                                                <i className="fas fa-star" />
                                                            </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                            </div>
                                                            <p className="text-light-black">Delivery was fast and friendly...</p>
                                                            <p className="text-light-white fw-100"><strong className="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                                            <a href="checkout.html" className="btn-second btn-submit">Order Now</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-wrapper">
                                                    <div className="testimonial-box">
                                                        <div className="testimonial-img p-relative">
                                                            <a href="restaurant.html">
                                                                <img src="https://via.placeholder.com/438x180" className="img-fluid full-width" alt="testimonial-img" />
                                                            </a>
                                                            <div className="overlay">
                                                                <div className="brand-logo">
                                                                    <img src="https://via.placeholder.com/50" className="img-fluid" alt="logo" />
                                                                </div>
                                                                <div className="add-fav text-success"><img src="/assets/img/svg/013-heart-1.svg" alt="tag" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="testimonial-caption padding-15">
                                                            <p className="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                                            <h5 className="fw-600"><a href="restaurant.html" className="text-light-black">GSA King Tomato Farm</a></h5>
                                                            <div className="testimonial-user-box">
                                                                <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                                                <div className="testimonial-user-name">
                                                                    <p className="text-light-black fw-600">Sarra</p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                                                </div>
                                                            </div>
                                                            <div className="ratings"> <span className="text-yellow fs-16">
                                                                <i className="fas fa-star" />
                                                            </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                            </div>
                                                            <p className="text-light-black">Delivery was fast and friendly...</p>
                                                            <p className="text-light-white fw-100"><strong className="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                                            <a href="checkout.html" className="btn-second btn-submit">Order Now</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-wrapper">
                                                    <div className="testimonial-box">
                                                        <div className="testimonial-img p-relative">
                                                            <a href="restaurant.html">
                                                                <img src="https://via.placeholder.com/438x180" className="img-fluid full-width" alt="testimonial-img" />
                                                            </a>
                                                            <div className="overlay">
                                                                <div className="brand-logo">
                                                                    <img src="https://via.placeholder.com/50" className="img-fluid" alt="logo" />
                                                                </div>
                                                                <div className="add-fav text-success"><img src="/assets/img/svg/013-heart-1.svg" alt="tag" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="testimonial-caption padding-15">
                                                            <p className="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                                            <h5 className="fw-600"><a href="restaurant.html" className="text-light-black">GSA King Tomato Farm</a></h5>
                                                            <div className="testimonial-user-box">
                                                                <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                                                <div className="testimonial-user-name">
                                                                    <p className="text-light-black fw-600">Sarra</p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                                                </div>
                                                            </div>
                                                            <div className="ratings"> <span className="text-yellow fs-16">
                                                                <i className="fas fa-star" />
                                                            </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                                <span className="text-yellow fs-16">
                                                                    <i className="fas fa-star" />
                                                                </span>
                                                            </div>
                                                            <p className="text-light-black">Delivery was fast and friendly...</p>
                                                            <p className="text-light-white fw-100"><strong className="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                                            <a href="checkout.html" className="btn-second btn-submit">Order Now</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Add Arrows */ }
                                        <div className="swiper-button-next" />
                                        <div className="swiper-button-prev" />
                                    </div>
                                    <div className="card sidebar-card">
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12 restaurent-meal-head mb-md-40">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="section-header-left">
                                                        <h3 className="text-light-black header-title">
                                                            <a className="card-link text-light-black no-margin" data-toggle="collapse" href="#collapseOne">
                                                                Our Dishies
                                                             </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div id="collapseOne" className="collapse show">
                                                    <div className="card-body no-padding">
                                                        <div className="row">
                                                            {
                                                                this.LoadDishiesList()
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3">
                                    <div className="sidebar">
                                        <div className="cart-detail-box">
                                            <div className="card">
                                                <div className="card-header padding-15 fw-700">Your Order</div>
                                                { this.FinalCart() }
                                                <div className="card-footer padding-15"> <a href="checkout.html " className="btn-first green-btn text-custom-white full-width fw-500">Proceed to Checkout</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* restaurent meals */ }
                    {/* restaurent about */ }
                    <section className="section-padding restaurent-about smoothscroll" id="about">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="text-light-black fw-700 title">{ this.state.RestarentData.BussinessName } Menu Info</h3>
                                    <p className="text-light-green no-margin">{ this.state.RestarentData.CuisineType }</p>
                                    <p className="text-light-white no-margin"></p> <span className="text-success fs-16">$</span>
                                    <span className="text-success fs-16">$</span>
                                    <span className="text-success fs-16">$</span>
                                    <span className="text-dark-white fs-16">$</span>
                                    <span className="text-dark-white fs-16">$</span>
                                    <ul className="about-restaurent">
                                        <li> <i className="fas fa-map-marker-alt" />
                                            <span>
                                                <a href="#" className="text-light-white">
                                                    { this.state.RestarentData.Address1 }
                                                </a>
                                            </span>
                                        </li>
                                        <li> <i className="fas fa-phone-alt" />
                                            <span><a href="tel:" className="text-light-white">{ this.state.RestarentData.BussinessMobileNo }</a></span>
                                        </li>
                                        <li> <i className="far fa-envelope" />
                                            <span><a href="mailto:" className="text-light-white">{ this.state.RestarentData.BussinessEmail }</a></span>
                                        </li>
                                    </ul>
                                    {/* <ul className="social-media pt-2">
                                        <li> <a href="#"><i className="fab fa-facebook-f" /></a>
                                        </li>
                                        <li> <a href="#"><i className="fab fa-twitter" /></a>
                                        </li>
                                        <li> <a href="#"><i className="fab fa-instagram" /></a>
                                        </li>
                                        <li> <a href="#"><i className="fab fa-pinterest-p" /></a>
                                        </li>
                                        <li> <a href="#"><i className="fab fa-youtube" /></a>
                                        </li>
                                    </ul> */}
                                </div>
                                <div className="col-md-6">
                                    <div className="restaurent-schdule">
                                        <div className="card">
                                            <div className="card-header text-light-white fw-700 fs-16">Hours</div>
                                            <div className="card-body">
                                                <div className="schedule-box">
                                                    <div className="day text-light-black">Today</div>
                                                    <div className="time text-light-black">Delivery: 7:00am - 10:59pm</div>
                                                </div>
                                                <div className="collapse" id="schdule">
                                                    <div className="schedule-box">
                                                        <div className="day text-light-black">Today</div>
                                                        <div className="time text-light-black">Delivery: 7:00am - 10:59pm</div>
                                                    </div>
                                                    <div className="schedule-box">
                                                        <div className="day text-light-black">Today</div>
                                                        <div className="time text-light-black">Delivery: 7:00am - 10:59pm</div>
                                                    </div>
                                                    <div className="schedule-box">
                                                        <div className="day text-light-black">Today</div>
                                                        <div className="time text-light-black">Delivery: 7:00am - 10:59pm</div>
                                                    </div>
                                                    <div className="schedule-box">
                                                        <div className="day text-light-black">Today</div>
                                                        <div className="time text-light-black">Delivery: 7:00am - 10:59pm</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer"> <a className="fw-500 collapsed" data-toggle="collapse" href="#schdule">See the full schedule</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* restaurent about */ }
                    {/* map gallery */ }
                    {/* <div className="map-gallery-sec section-padding bg-light-theme smoothscroll" id="mapgallery">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-box">
                                        <div className="row">
                                            <div className="col-md-6 map-pr-0">
                                                <iframe id="locmap" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed" />
                                            </div>
                                            <div className="col-md-6 map-pl-0">
                                                <div className="gallery-box padding-10">
                                                    <ul className="gallery-img">
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* map gallery */ }
                    {/* restaurent reviews */ }
                    <section className="section-padding restaurent-review smoothscroll" id="review">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-header-left">
                                        <h3 className="text-light-black header-title title">Reviews for Great Burger</h3>
                                    </div>
                                    <div className="restaurent-rating mb-xl-20">
                                        <div className="star"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-dark-white fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-dark-white fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                        </div> <span className="fs-12 text-light-black">58 Ratings</span>
                                    </div>
                                    <p className="text-light-black mb-xl-20">Here's what people are saying:</p>
                                    <ul>
                                        <li>
                                            <h6 className="text-light-black mb-1">73%</h6>
                                            <span className="text-light-black fs-12 fw-400">Food was good</span>
                                        </li>
                                        <li>
                                            <h6 className="text-light-black mb-1">85%</h6>
                                            <span className="text-light-black fs-12 fw-400">Delivery was on time</span>
                                        </li>
                                        <li>
                                            <h6 className="text-light-black mb-1">68%</h6>
                                            <span className="text-light-black fs-12 fw-400">Order was accurate</span>
                                        </li>
                                    </ul>
                                    <div className="u-line" />
                                </div>
                                <div className="col-md-12">
                                    <div className="review-box">
                                        <div className="review-user">
                                            <div className="review-user-img">
                                                <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                                <div className="reviewer-name">
                                                    <p className="text-light-black fw-600">Sarra <small className="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div className="review-date"> <span className="text-light-white">Sep 20, 2019</span>
                                            </div>
                                        </div>
                                        <div className="ratings"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="ml-2 text-light-white">2 days ago</span>
                                        </div>
                                        <p className="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p> <span className="text-light-white fs-12 food-order">Kathy
                                        ordered:</span>
                                        <ul className="food">
                                            <li>
                                                <button className="add-pro bg-gradient-red">Coffee <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-dark">Pizza <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-green">Noodles <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-orange">Burger <span className="close">+</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="review-box">
                                        <div className="review-user">
                                            <div className="review-user-img">
                                                <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                                <div className="reviewer-name">
                                                    <p className="text-light-black fw-600">Sarra <small className="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div className="review-date"> <span className="text-light-white">Sep 20, 2019</span>
                                            </div>
                                        </div>
                                        <div className="ratings"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="ml-2 text-light-white">2 days ago</span>
                                        </div>
                                        <p className="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p> <span className="text-light-white fs-12 food-order">Kathy
                    ordered:</span>
                                        <ul className="food">
                                            <li>
                                                <button className="add-pro bg-gradient-red">Coffee <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-dark">Pizza <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-green">Noodles <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-orange">Burger <span className="close">+</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="review-box">
                                        <div className="review-user">
                                            <div className="review-user-img">
                                                <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                                <div className="reviewer-name">
                                                    <p className="text-light-black fw-600">Sarra <small className="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div className="review-date"> <span className="text-light-white">Sep 20, 2019</span>
                                            </div>
                                        </div>
                                        <div className="ratings"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="ml-2 text-light-white">2 days ago </span>
                                        </div>
                                        <p className="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p> <span className="text-light-white fs-12 food-order">Kathy
                    ordered:</span>
                                        <ul className="food">
                                            <li>
                                                <button className="add-pro bg-gradient-red">Coffee <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-dark">Pizza <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-green">Noodles <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-orange">Burger <span className="close">+</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="review-img">
                                        <img src="/assets/img/review-footer.png" className="img-fluid" alt="#" />
                                        <div className="review-text">
                                            <h2 className="text-light-white mb-2 fw-600">Be one of the first to review</h2>
                                            <p className="text-light-white">Order now and write a review to give others the inside scoop.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* restaurent reviews */ }
                    {/* offer near */ }
                    <section className="fresh-deals section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-header-left">
                                        <h3 className="text-light-black header-title title">Offers near you</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="fresh-deals-slider swiper-container">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <a href="restaurant.html">
                                                            <img src="https://via.placeholder.com/255x150" className="img-fluid full-width" alt="product-img" />
                                                        </a>
                                                    </div>
                                                    <div className="product-caption">
                                                        <div className="title-box">
                                                            <h6 className="product-title"><a href="restaurant.html" className="text-light-black">Great Burger</a></h6>
                                                        </div>
                                                        <p className="text-light-white">American, Fast Food</p>
                                                        <div className="product-details">
                                                            <div className="price-time"> <span className="text-light-black time">30-40 min</span>
                                                                <span className="text-light-white price">$10 min</span>
                                                            </div>
                                                            <div className="rating"> <span>
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                            </span>
                                                                <span className="text-light-white text-right">4225 ratings</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-footer-2">
                                                        <div className="discount"> <span className="text-success fs-12">$3 off</span>
                                                        </div>
                                                        <div className="discount-coupon"> <span className="text-light-white fs-12">First order only</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <a href="restaurant.html">
                                                            <img src="https://via.placeholder.com/255x150" className="img-fluid full-width" alt="product-img" />
                                                        </a>
                                                    </div>
                                                    <div className="product-caption">
                                                        <div className="title-box">
                                                            <h6 className="product-title"><a href="restaurant.html" className="text-light-black">Flavor Town</a></h6>
                                                        </div>
                                                        <p className="text-light-white">Breakfast, Lunch &amp; Dinner</p>
                                                        <div className="product-details">
                                                            <div className="price-time"> <span className="text-light-black time">30-40 min</span>
                                                                <span className="text-light-white price">$10 min</span>
                                                            </div>
                                                            <div className="rating"> <span>
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                            </span>
                                                                <span className="text-light-white text-right">4225 ratings</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-footer-2">
                                                        <div className="discount"> <span className="text-success fs-12">$3 off</span>
                                                        </div>
                                                        <div className="discount-coupon"> <span className="text-light-white fs-12">First order only</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <a href="restaurant.html">
                                                            <img src="https://via.placeholder.com/255x150" className="img-fluid full-width" alt="product-img" />
                                                        </a>
                                                    </div>
                                                    <div className="product-caption">
                                                        <div className="title-box">
                                                            <h6 className="product-title"><a href="restaurant.html" className="text-light-black">Big Bites</a></h6>
                                                        </div>
                                                        <p className="text-light-white">Pizzas, Fast Food</p>
                                                        <div className="product-details">
                                                            <div className="price-time"> <span className="text-light-black time">30-40 min</span>
                                                                <span className="text-light-white price">$10 min</span>
                                                            </div>
                                                            <div className="rating"> <span>
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                            </span>
                                                                <span className="text-light-white text-right">4225 ratings</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-footer-2">
                                                        <div className="discount"> <span className="text-success fs-12">$3 off</span>
                                                        </div>
                                                        <div className="discount-coupon"> <span className="text-light-white fs-12">First order only</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <a href="restaurant.html">
                                                            <img src="https://via.placeholder.com/255x150" className="img-fluid full-width" alt="product-img" />
                                                        </a>
                                                    </div>
                                                    <div className="product-caption">
                                                        <div className="title-box">
                                                            <h6 className="product-title"><a href="restaurant.html" className="text-light-black">Smile N’ Delight</a></h6>
                                                        </div>
                                                        <p className="text-light-white">Desserts, Beverages</p>
                                                        <div className="product-details">
                                                            <div className="price-time"> <span className="text-light-black time">30-40 min</span>
                                                                <span className="text-light-white price">$10 min</span>
                                                            </div>
                                                            <div className="rating"> <span>
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                            </span>
                                                                <span className="text-light-white text-right">4225 ratings</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-footer-2">
                                                        <div className="discount"> <span className="text-success fs-12">$3 off</span>
                                                        </div>
                                                        <div className="discount-coupon"> <span className="text-light-white fs-12">First order only</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <a href="restaurant.html">
                                                            <img src="https://via.placeholder.com/255x150" className="img-fluid full-width" alt="product-img" />
                                                        </a>
                                                    </div>
                                                    <div className="product-caption">
                                                        <div className="title-box">
                                                            <h6 className="product-title"><a href="restaurant.html" className="text-light-black">Lil Johnny’s</a></h6>
                                                        </div>
                                                        <p className="text-light-white">Continental &amp; Mexican</p>
                                                        <div className="product-details">
                                                            <div className="price-time"> <span className="text-light-black time">30-40 min</span>
                                                                <span className="text-light-white price">$10 min</span>
                                                            </div>
                                                            <div className="rating"> <span>
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                                <i className="fas fa-star text-yellow" />
                                                            </span>
                                                                <span className="text-light-white text-right">4225 ratings</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-footer-2">
                                                        <div className="discount"> <span className="text-success fs-12">$3 off</span>
                                                        </div>
                                                        <div className="discount-coupon"> <span className="text-light-white fs-12">First order only</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Add Arrows */ }
                                        <div className="swiper-button-next" />
                                        <div className="swiper-button-prev" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* offer near */ }
                </div>
                {/* Restaurnet body end */ }
                <Footer></Footer>

                {this.ModelPopupItemList() }
            </>
        )
    }
}
