import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Accordion, Card } from "react-bootstrap";
import Config from '../../Config'

class LoginContent extends Component {

    constructor (props, context) {
        super(props, context);
        this.handleShow1 = this.handleShow1.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            show: false,
            UserName: "",
            Password: "",
            error_description: "",
            isChecked: false
        };
        localStorage.removeItem("tokenData");
        this._handleSubmitEvent = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        //initialize datatable
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');

                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);


        }
        formValidation();

        // let savedLoginData = JSON.parse(localStorage.getItem("savedLoginData"));
        // if (savedLoginData) {
        //     if (savedLoginData.isChecked) {
        //         this.setState({ UserName: savedLoginData.UserName, Password: savedLoginData.Password, isChecked: savedLoginData.isChecked })
        //         document.getElementById("validationCustom08").value = savedLoginData.UserName;
        //         document.getElementById("validationCustom09").value = savedLoginData.Password;
        //     }
        //     else {
        //         localStorage.removeItem("savedLoginData");
        //     }
        // }

    }

    handleShow1() {
        this.setState({ show1: true });
    }

    handleClose() {
        this.setState({ show1: false });
    }

    handleUserName = event => {
        this.setState({
            UserName: event.target.value
        })

    }

    handlePassword = event => {
        this.setState({
            Password: event.target.value
        })
    }
    onChangeCheckbox = event => {
        this.setState({
            isChecked: event.target.checked
        })
    }

    handleSubmit = event => {

        event.preventDefault()

        var currentLocation = window['getCurrentLocation']();

        if (event.target.checkValidity()) {

            let currentcomponent = this;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("grant_type", "password");
            urlencoded.append("username", this.state.UserName);
            urlencoded.append("password", this.state.Password);
            urlencoded.append("Scope", 'Customer');
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };



            fetch(Config.apiTokenURL, requestOptions)
                .then(response => response.text())
                .then(result => {

                    var sessionobj = JSON.parse(result);
                    if (sessionobj["error_description"]) {
                        this.setState({ error_description: sessionobj["error_description"] })
                    }
                    else {

                        var savedLoginData = {
                            UserName: this.state.UserName,
                            Password: this.state.Password,
                            isChecked: this.state.isChecked
                        }



                        localStorage.setItem("savedLoginData", JSON.stringify(savedLoginData));
                        localStorage.setItem("tokenData", JSON.stringify(sessionobj))

                        //window.location.href = sessionobj.RedirectURL;
                        window.location.href = '/CustomerProfile';
                    }
                }
                ).catch(error => console.log('error', error));

        }

    }

    render() {
        return (
            <>
                <div className="inner-wrapper">
                    <div className="container-fluid no-padding">
                        <div className="row no-gutters overflow-auto">
                            <div className="col-md-6">
                                <div className="main-banner">
                                    <img src="https://via.placeholder.com/952x646" className="img-fluid full-width main-img" alt="banner" />
                                    <div className="overlay-2 main-padding">
                                        <img src="https://via.placeholder.com/190x111" className="img-fluid" alt="logo" />
                                    </div>
                                    <img src="https://via.placeholder.com/340x220" className="footer-img" alt="footer-img" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="section-2 user-page main-padding">
                                    <div className="login-sec">
                                        <div className="login-box">
                                            <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent }>
                                                <h4 className="text-light-black fw-600">Sign in with your ChefMeF account</h4>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="text-light-black">Have a corporate username? <a href="http://admin.harshpatel.in/Cook">Click here</a>
                                                        </p>
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">Email/User Name /Phone No.</label>
                                                            <input type="text" name="#" name="UserName" id="UserName" className="form-control form-control-submit" placeholder="Email I'd" required onChange={ this.handleUserName } />
                                                            <div className="invalid-feedback">Please enter field.</div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">Password</label>
                                                            <input type="password" id="Password" name="Password" className="form-control form-control-submit" placeholder="Password" required onChange={ this.handlePassword } />
                                                            {/* <div data-name="#password-field" className="fa fa-fw fa-eye field-icon toggle-password"></div> */ }
                                                            <div className="invalid-feedback">Please enter password.</div>
                                                        </div>
                                                        <div className="form-group checkbox-reset">
                                                            <label className="custom-checkbox mb-0">
                                                                <input type="checkbox" name="#" defaultValue onChange={ this.onChangeCheckbox } checked={ this.state.isChecked } /> <span className="checkmark"></span> Remember Password</label> <a href="#">Reset password</a>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn-second btn-submit full-width">
                                                                <img src="assets/img/M.png" alt="btn logo" />Sign in</button>
                                                        </div>
                                                        {/* <div className="form-group text-center"> <span>or</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn-second btn-facebook full-width">
                                                                <img src="assets/img/facebook-logo.svg" alt="btn logo" />Continue with Facebook</button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn-second btn-google full-width">
                                                                <img src="assets/img/google-logo.png" alt="btn logo" />Continue with Google</button>
                                                        </div>
                                                        <div className="form-group text-center mb-0"> <a href="register.html">Create your account</a>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LoginContent;